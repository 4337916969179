import { Typography } from "@mui/material";
import Head from "next/head";
import ClientRoadmap, {
  ClientRoadmapFragment,
} from "src/components/client-roadmap";
import { DashboardLayout } from "src/components/dashboard/dashboard-layout";
import { ClientStage } from "shared/generated/graphql";
import { useAuth } from "shared/hooks/use-auth";
import { DateTime } from "luxon";
import { withClientGuard } from "src/hocs/with-client-guard";
import { useEffect } from "react";
import { XCircularLoadingIndicator } from "shared/x-components/x-circular-loading-indicator";
import PortfolioSplashScreen from "src/components/loading-and-splash/portfolio-splash-screen";
import { showPortfolioSplashScreen } from "src/utils/client-roadmap";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { graphql } from "shared/gql";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";

export const dashboardQuery = graphql(
  /* GraphQL */ `
    query TasksFromDashboard {
      tasks(household: true) {
        ...ClientRoadmap
      }
    }
  `,
  [ClientRoadmapFragment]
);

// FIXME: Would be better to run a single query rather than 2
const CLIENT_QUERY = graphql(/* GraphQL */ `
  query ClientQueryFromDashboard {
    client {
      providerAccounts {
        source
      }
    }
  }
`);

export function Dashboard() {
  const { user } = useAuth();
  const isSmallScreen = useIsSmallScreen();
  const isUserFullyOnboarded = user?.clientStage === ClientStage.FullyOnboarded;
  const { loading: taskLoading, data: taskData } = useQueryWithErrorLogging(
    dashboardQuery,
    {}
  );
  const { loading: clientLoading, data: clientData } =
    useQueryWithErrorLogging(CLIENT_QUERY);

  useEffect(() => {
    if (isUserFullyOnboarded) {
      window.location.href = "/portfolio";
    }
  }, [isUserFullyOnboarded]);

  if (taskLoading || clientLoading) {
    return <XCircularLoadingIndicator />;
  }

  if (
    user?.clientStage &&
    // @ts-expect-error - ProviderAccounts.source is a string type and will be updated in the future
    showPortfolioSplashScreen(user.clientStage, clientData?.client)
  ) {
    return <PortfolioSplashScreen />;
  } else if (isUserFullyOnboarded) {
    return null;
  }
  return (
    <>
      {isSmallScreen ? (
        <ClientRoadmap taskFrags={taskData?.tasks ?? []} />
      ) : (
        <DashboardLayout
          headerTitle={`Hello, ${user?.firstName}!`}
          hideBorder
          headerRightContent={
            <Typography variant="subtitle1" color="textSecondary">
              {DateTime.now().toFormat("LLLL dd, yyyy")}
            </Typography>
          }
        >
          <Head>
            <title>Progress</title>
          </Head>
          <>
            <ClientRoadmap taskFrags={taskData?.tasks ?? []} />
          </>
        </DashboardLayout>
      )}
    </>
  );
}

export default withClientGuard(Dashboard);
