import {
  SalesforceActivityHistory,
  SalesforceActivitySubtype,
} from "../../types/salesforce-account";
import { SalesforceEmailMessage } from "../../types/salesforce-email-message";
import { Note } from "shared/generated/graphql";
import { SalesforceTask, XLR8CS__Category__c } from "src/types/salesforce-task";
import { SalesforceEvent } from "src/types/salesforce-event";

export type SalesforceActivityHistoryItem = SalesforceActivityHistory & {
  type: "SalesforceActivityHistory";
};
export type SalesforceActivityHistoryCallItem =
  SalesforceActivityHistoryItem & {
    ActivitySubtype: SalesforceActivitySubtype.Call;
  };
export type SalesforceEmailMessageItem = SalesforceEmailMessage & {
  type: "SalesforceEmailMessage";
};
export type NoteItem = Note & { type: "Note" };
export type SalesforceTaskItem = SalesforceTask & { type: "SalesforceTask" };
export type SalesforceEventItem = SalesforceEvent & { type: "SalesforceEvent" };
export type AssignedCampaignItem = {
  type: "AssignedCampaign";
};
// SalesforceActivityHistoryCard_ClientFragment["user"]["assignedCampaigns"][number] & {
//   type: "AssignedCampaign";
// };

export type SalesforceActivityItem =
  | SalesforceActivityHistoryItem
  | SalesforceActivityHistoryCallItem
  | SalesforceEmailMessageItem
  | NoteItem
  | SalesforceTaskItem
  | SalesforceEventItem
  | AssignedCampaignItem;

export function isCompletedCampaign(
  _activity: SalesforceActivityItem
): _activity is AssignedCampaignItem {
  return false;
  // return activity.type === "AssignedCampaign";
}

export function isEmailMessage(
  activity: SalesforceActivityItem
): activity is SalesforceEmailMessageItem {
  return activity.type === "SalesforceEmailMessage";
}

export function isCall(
  activity: SalesforceActivityItem
): activity is SalesforceActivityHistoryCallItem {
  return (
    activity.type === "SalesforceActivityHistory" &&
    activity.ActivitySubtype === SalesforceActivitySubtype.Call
  );
}

export function isDeftSalesSMS({ Subject }: SalesforceActivityHistory) {
  const isDeftSalesSubject =
    Subject === "DeftSales Task Note" || // Previous subject string created for DeftSales SMS activity
    Subject === "DeftSales SMS Sent Note" ||
    Subject === "DeftSales SMS Received" ||
    Subject === "DeftSales SMS Received Note";
  return isDeftSalesSubject;
}

export function isSalesforceActivityHistory(
  activity: SalesforceActivityItem
): activity is SalesforceActivityHistoryItem {
  return activity.type === "SalesforceActivityHistory";
}

export function isNote(activity: SalesforceActivityItem): activity is NoteItem {
  return activity.type === "Note";
}

export function isEvent(
  activity: SalesforceActivityItem
): activity is SalesforceEventItem {
  return (
    activity.type === "SalesforceEvent" && activity.StartDateTime !== undefined
  );
}

export function isTask(
  activity: SalesforceActivityItem
): activity is SalesforceTaskItem {
  return (
    activity.type === "SalesforceTask" &&
    activity.XLR8CS__Category__c !== undefined
  );
}

export function isInternalTask(
  activity: SalesforceActivityItem
): activity is SalesforceTaskItem {
  return (
    activity.type === "SalesforceTask" &&
    activity.XLR8CS__Category__c !== XLR8CS__Category__c.ClientTask
  );
}

function getActivityDateToSortOn(activity?: SalesforceActivityItem) {
  if (!activity) {
    return "";
  }
  if (isEmailMessage(activity)) {
    return activity.MessageDate || ""; // 2022-03-08T00:00:00.000+0000
  } else if (isCall(activity)) {
    return (
      activity.Dialpad__Call_End_Time__c ||
      activity.ActivityDate ||
      activity.CreatedDate ||
      ""
    ); // 2022-03-08
  } else if (isTask(activity)) {
    return activity.CreatedDate || ""; // 2022-03-08
  } else if (isSalesforceActivityHistory(activity)) {
    return (
      // Tasks have a CompletedDateTime field, but it's not always populated
      activity.CompletedDateTime ||
      // Events have a StartDateTime field, but it's not always populated
      activity.StartDateTime ||
      activity.ActivityDate ||
      activity.CreatedDate ||
      ""
    ); // 2022-03-08
  } else if (isNote(activity)) {
    return activity.createdAt || ""; // 2023-03-10T17:23:27Z
  } else if (isEvent(activity)) {
    return activity.StartDateTime || activity.ActivityDate || ""; // 2023-03-10T17:23:27Z || 2022-03-08
  }
  return "";
}

export function compareActivitiesByDate(
  a: SalesforceActivityItem,
  b: SalesforceActivityItem
) {
  const aDate = getActivityDateToSortOn(a);
  const bDate = getActivityDateToSortOn(b);
  if (aDate < bDate) {
    return 1;
  }
  if (aDate > bDate) {
    return -1;
  }
  // Tiebreak on CreatedDate, only applicable to logged calls for now
  if (isSalesforceActivityHistory(a) && isSalesforceActivityHistory(b)) {
    return b.CreatedDate.localeCompare(a.CreatedDate);
  }
  return 0;
}
