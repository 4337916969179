import { useMutationWithErrorLogging } from "shared/hooks/useMutationWithErrorLogging";
import { useRouter } from "next/router";
import {
  Client,
  ClientStage,
  UpdateClientProfileInput,
} from "shared/generated/graphql";
import { useAuth } from "shared/hooks/use-auth";
import { XCircularLoadingIndicator } from "shared/x-components/x-circular-loading-indicator";
import IntroDoormat from "../guided-flow/intro-doormat";
import { useQueryWithErrorLogging } from "shared/hooks/useQueryWithErrorLogging";
import { graphql } from "shared/gql";

const UPDATE_CLIENT_PROFILE = graphql(/* GraphQL */ `
  mutation UpdateClientProfileFromPortfolioSplashScreen(
    $updateInput: UpdateClientProfileInput!
  ) {
    updateClientProfile(input: $updateInput) {
      clientProfile {
        id
        stage
      }
    }
  }
`);

const CLIENT_QUERY = graphql(/* GraphQL */ `
  query ClientFromPortfolioSplashScreen {
    client {
      clientProfileId
      user {
        firstName
        lastName
        profilePicUrl
      }
      assignedAdvisor {
        user {
          firstName
          lastName
          email
          profilePicUrl
        }
      }
    }
  }
`);

export default function PortfolioSplashScreen() {
  const router = useRouter();

  const [updateClientProfile] = useMutationWithErrorLogging(
    UPDATE_CLIENT_PROFILE
  );
  const { data: clientData, loading: clientLoading } =
    useQueryWithErrorLogging(CLIENT_QUERY);
  const { user: authUser, update } = useAuth();
  async function updateClientProfileField(
    updateInput: UpdateClientProfileInput
  ) {
    const res = await updateClientProfile({
      variables: {
        // @ts-expect-error Will self-resolve with better typing
        updateInput,
      },
    });

    await update({
      ...authUser,
      clientStage: res.data?.updateClientProfile?.clientProfile.stage,
    });
    setTimeout(() => {
      router.replace("/portfolio");
    });
  }

  if (clientLoading) {
    return <XCircularLoadingIndicator />;
  }

  // TODO: Remove this cast
  const client = clientData?.client as Client;

  return (
    <IntroDoormat
      header="Congratulations!"
      body="Your account setup and onboarding is complete! We’re excited to have you on board as a Savvy client."
      buttonText="Unveil your dashboard"
      onContinue={() =>
        updateClientProfileField({
          id: client.clientProfileId!,
          stage: ClientStage.FullyOnboarded,
        })
      }
    />
  );
}
