import { TSDateTimeISOString, TSDateYMDString } from "./datetime";
import { SalesforceContact } from "./salesforce-contact";
import {
  XLR8CS__Category__c,
  XLR8CS__Sub_Category_Task__c,
} from "./salesforce-task";

export type SalesforceAccount = {
  Advice_only_client__c?: boolean;
  Advisor_Annual_Revenue__c?: string;
  CreatedDate: TSDateTimeISOString;
  Name?: string;
  Id: string;
  Savvy_User_Id__c?: string;
  Lead_Source__c?: Lead_Source__c;
  Dependents__c?: string;
  Other_Income__c?: string;
  Monthly_Savings_and_Investments__c?: string;
  Monthly_Expenses__c?: string;
  LastModifiedDate: TSDateTimeISOString;
  Other_Financial_Information__c?: string;
  Other_Financial_Goals__c?: string;
  Last_Contact_Date__c?: TSDateTimeISOString;
  Orion_Household_ID__c?: string;
  Estimated_AUM__c?: string;
  Savvy_AUM__c?: string;
  Probability_of_Closing__c?: string;
  XLR8CS__Stage__c?: XLR8CS__Stage__c;
  Loss_Reason__c?: string;
  XLR8CS__Bio__c?: string;
  Contacts?: {
    records: SalesforceContact[];
  };
  OwnerId?: string;
  Fee_Schedule__c?: string;
  Billing_Start_Date__c?: TSDateYMDString;
  // Save me for the admin view
  Savvy_Client_Stage__c?: Savvy_Client_Stage__c;
  Owner?: {
    Name?: string;
  };
  Family_Info__c?: string;
  Qualified_Client__c: boolean;
  Qualified_Client_Rationale__c?: string;
  Qualified_Purchaser__c: boolean;
  Qualified_Purchaser_Rationale__c?: string;
  Accredited_Investor__c: boolean;
  Accredited_Investor_Rationale__c?: string;
  RecordTypeId?: string;
  ActivityHistories?: {
    records: SalesforceActivityHistory[];
  };
  Referral_Contact_Name__c?: string;
  Referral_Contact__c?: string;
  XLR8CS__Client_Since__c?: TSDateYMDString;
  nextRecordsUrl?: string;
};

export type SalesforceActivityHistory = {
  Id: string;
  AccountId?: string;
  AccountTransferStatus__c?: string;
  ActivityType?: SalesforceActivityType;
  ActivityDate?: TSDateYMDString;
  ActivityDateTime?: TSDateTimeISOString;
  Automatically_Load_Investments_To_eMoney__c?: boolean;
  Subject?: string;
  CallDisposition?: string;
  CallDurationInSeconds?: number;
  CallObject?: string;
  CallType?: SalesforceCallType;
  CreatedDate: TSDateYMDString;
  CreatedById: string;
  CompletedDateTime?: TSDateTimeISOString;
  Description?: string;
  Dialpad__CallId__c?: string;
  Dialpad__Call_End_Time__c?: string;
  DurationInMinutes?: number;
  ActivitySubtype: SalesforceActivitySubtype;
  attributes?: {
    type: string;
    url: string;
  };
  Location?: string;
  StartDateTime?: TSDateTimeISOString;
  OwnerId?: string;
  SendClientEmailReminders__c?: boolean;
  EndDateTime?: TSDateTimeISOString;
  IsAllDayEvent: boolean;
  Investment_Model_Recommendation__c?: string;
  Investment_Proposal_Generation_Source__c?: string;
  IsReadyForClient__c?: boolean;
  Notify_client_upon_creation__c?: boolean;
  Presentation_Link__c?: string;
  Presentation_Slides__c?: string;
  Savvy_Task_ID__c?: string;
  Savvy_Task_URL__c?: string;
  Proposal_Account_Statements_Folder__c?: string;
  WhatId?: string;
  WhoId?: string;
  XLR8CS__Category__c?: XLR8CS__Category__c;
  XLR8CS__Sub_Category_Task__c?: XLR8CS__Sub_Category_Task__c;
  Status?: SalesforceActivityStatus;
  __typename: string;
};

export enum SalesforceActivityStatus {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  WAITING_ON_SOMEONE_ELSE = "Waiting on someone else",
  DEFERRED = "Deferred",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
}

export enum SalesforceActivityType {
  CLIENT_TASK = "Client Task",
  CONFERENCE_CALL = "Conference Call",
  EMAIL = "Email",
  EVENT = "Event",
  GO_TO_MEETING = "Go To Meeting",
  MEETING_EXTERNAL = "Meeting - External",
  MEETING_INTERNAL = "Meeting - Internal",
  PHONE = "Phone",
  TO_DO = "To Do",
  SMS = "SMS",
}

export enum Lead_Source__c {
  "Advisor Existing Client" = "Advisor Existing Client",
  "Savvy Generated" = "Savvy Generated",
  "Centers of Influence" = "Centers of Influence",
  "Client referral" = "Client referral",
  "Advisor Generated" = "Advisor Generated",
  "SmartAsset" = "SmartAsset",
  "AgouraGrowth" = "AgouraGrowth",
  "WiserAdvisor" = "WiserAdvisor",
  "PlansWell" = "PlansWell",
  "Harness Wealth" = "Harness Wealth",
  "XYPN/NAPFA" = "XYPN/NAPFA",
  "Other" = "Other",
}

export enum XLR8CS__Stage__c {
  "Lead" = "Lead",
  "Discovery" = "Discovery",
  "Proposal" = "Proposal",
  "Commitment" = "Commitment",
  "Onboarding" = "Onboarding",
  "On hold" = "On hold",
  "Lost" = "Lost",
  "Won" = "Won",
}

export enum Savvy_Client_Stage__c {
  "IntakeFlow" = "intake_flow",
  "CompletedIntakeFlow" = "post_intake_waiting_area",
  "AccountSetupFlow" = "account_setup_flow",
  "StartedOnboardingFlow" = "onboarding_flow",
  "CompletedOnboardingFlow" = "post_onboarding_waiting_area",
  "FullyOnboarded" = "fully_onboarded",
}

export enum SalesforceCallType {
  "Internal" = "Internal",
  "Inbound" = "Inbound",
  "Outbound" = "Outbound",
}

export enum SalesforceActivitySubtype {
  "Task" = "Task",
  "Email" = "Email",
  "List Email" = "List Email",
  "Cadence" = "Cadence",
  "Call" = "Call",
  "Event" = "Event",
  "LinkedIn" = "LinkedIn",
}
