import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
import {
  BASE_CLIENT_ROADMAP_STAGE_ORDER,
  CLIENT_STAGE_TO_ROADMAP_STAGE,
  RoadmapStage,
} from "src/constants/client-roadmap";
import { useAuth } from "shared/hooks/use-auth";
import GradientProgressBar from "../loading-and-splash/gradient-progress";
import RoadmapStageCard, {
  RoadmapStageCardFragment,
} from "./roadmap-stage-card";
import { lastCompletedRoadMapStageIndex } from "src/utils/client-roadmap";
import { useTheme } from "shared/hooks/use-theme";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";
import scrollRefIntoView from "shared/utils/scroll-ref-into-view";
import { useIsVerySmallScreen } from "src/hooks/use-is-very-small-screen";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";

const HEADER_MAX_WIDTH = 390;
export const DEFAULT_ROADMAP_STAGE_CARD_WIDTH = 346;

export const ClientRoadmapFragment = graphql(
  /* GraphQL */ `
    fragment ClientRoadmap on Task {
      type
      ...RoadmapStageCard
    }
  `,
  [RoadmapStageCardFragment]
);

function scrollToRoadmapStage(
  scrollContainer: HTMLDivElement,
  roadmapStage: RoadmapStage
) {
  const roadmapCard = document.getElementsByClassName(
    `${roadmapStage}-card`
  )[0] as HTMLElement | null | undefined;
  smoothscroll.polyfill();

  if (roadmapCard == null) {
    return;
  }
  scrollContainer.scrollTo({
    left:
      Number(roadmapCard.offsetLeft) -
      Number(window.innerWidth) / 2 +
      DEFAULT_ROADMAP_STAGE_CARD_WIDTH,
    behavior: "smooth",
  });
}

export type Props = {
  taskFrags: FragmentOf<typeof ClientRoadmapFragment>[];
};
export default function ClientRoadmap({ taskFrags }: Props) {
  const isSmallScreen = useIsSmallScreen();
  const isVerySmallScreen = useIsVerySmallScreen();
  const { user } = useAuth();
  const theme = useTheme();
  const tasks = taskFrags.map((taskFrag) =>
    unmaskFragment(ClientRoadmapFragment, taskFrag)
  );

  const [scrollContainer, setScrollContainer] = useState<
    HTMLDivElement | undefined
  >(undefined);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  setTimeout(() => {
    isSmallScreen &&
      scrollRefIntoView(scrollRef, {
        block: isVerySmallScreen ? "end" : "center",
      });
  }, 30);

  const hasIntakeTask =
    tasks.filter((task) => task.type === "initial_intake_form").length > 0;
  const hasAccountSetupTask =
    tasks.filter((task) => task.type === "account_setup").length > 0;
  const orderedRoadmapStages = BASE_CLIENT_ROADMAP_STAGE_ORDER.filter(
    (stage) => {
      if (stage === RoadmapStage.INTAKE_FLOW && !hasIntakeTask) {
        return false;
      } else if (stage === RoadmapStage.ACCOUNT_SETUP && !hasAccountSetupTask) {
        return false;
      }
      return true;
    }
  );

  const clientRoadmapScrollRef = useCallback((node?: HTMLDivElement | null) => {
    if (node == null || isSmallScreen) {
      return;
    }
    setScrollContainer(node);

    setTimeout(() => {
      const currentStageIndex = lastCompletedRoadMapStageIndex(
        orderedRoadmapStages,
        user
      );
      currentStageIndex &&
        scrollToRoadmapStage(node, orderedRoadmapStages[currentStageIndex]);
    });

    // Vertical scrolling results in horizontal scroll
    node.addEventListener("wheel", (evt: WheelEvent) => {
      evt.preventDefault();
      if (evt.deltaX !== 0) {
        node.scrollLeft += evt.deltaX;
      } else if (evt.deltaY !== 0) {
        node.scrollLeft += evt.deltaY;
      }
    });
  }, []);

  const roadmapStage =
    user?.clientStage != null
      ? CLIENT_STAGE_TO_ROADMAP_STAGE[user.clientStage]
      : RoadmapStage.SIT_BACK_AND_WAIT;

  const [focusedRoadmapStageIndex, setFocusedRoadmapStageIndex] = useState(
    orderedRoadmapStages.indexOf(roadmapStage)
  );

  const prevButtonDisabled = focusedRoadmapStageIndex === 0;
  const nextButtonDisabled =
    focusedRoadmapStageIndex === orderedRoadmapStages.length - 1;
  const lastCompletedRoadmapStage = lastCompletedRoadMapStageIndex(
    orderedRoadmapStages,
    user
  );
  const regularScreenNavigationArrows = isSmallScreen ? null : (
    <Box sx={{ alignSelf: "flex-end" }}>
      <Box sx={{ px: 1.5, display: "inline" }}>
        <IconButton
          onClick={() => {
            setFocusedRoadmapStageIndex(focusedRoadmapStageIndex - 1);
            !!scrollContainer &&
              scrollToRoadmapStage(
                scrollContainer,
                orderedRoadmapStages[focusedRoadmapStageIndex - 1]
              );
          }}
          disabled={prevButtonDisabled}
          sx={{
            border: `1px solid ${
              prevButtonDisabled
                ? theme.palette.neutral[200]
                : theme.palette.background.contrast
            }`,
            borderRadius: 8,
          }}
        >
          <ArrowBack
            htmlColor={
              prevButtonDisabled ? theme.palette.neutral[200] : undefined
            }
          />
        </IconButton>
      </Box>
      <IconButton
        onClick={() => {
          setFocusedRoadmapStageIndex(focusedRoadmapStageIndex + 1);
          !!scrollContainer &&
            scrollToRoadmapStage(
              scrollContainer,
              orderedRoadmapStages[focusedRoadmapStageIndex + 1]
            );
        }}
        disabled={nextButtonDisabled}
        sx={{
          border: `1px solid ${
            nextButtonDisabled
              ? theme.palette.neutral[200]
              : theme.palette.background.contrast
          }`,
          borderRadius: 8,
        }}
      >
        <ArrowForward
          htmlColor={
            nextButtonDisabled ? theme.palette.neutral[200] : undefined
          }
        />
      </IconButton>
    </Box>
  );
  const cardSpacing = isSmallScreen ? 3 : 4;
  const cardWrapperStyles = isSmallScreen
    ? {}
    : {
        overflowX: "scroll",
        pr: `calc(50vw - ${DEFAULT_ROADMAP_STAGE_CARD_WIDTH / 2}px)`,
        pl: cardSpacing,
        flexWrap: "nowrap",
        position: "relative",
      };

  return (
    <>
      <Box
        sx={{
          pb: isSmallScreen ? 12 : 0,
          background: theme.palette.background.contrast,
          height: "100%",
        }}
      >
        {/* 100% opaque white background */}
        <Box position="sticky" top={0} sx={{ zIndex: 10 }}>
          <Stack
            px={isSmallScreen ? 3 : 5}
            pb={isSmallScreen ? 3 : 5}
            pt={isSmallScreen ? 8 : 3}
            sx={{ background: "#FFF" }}
            justifyContent="space-between"
          >
            <Stack sx={{ maxWidth: HEADER_MAX_WIDTH }} spacing={2}>
              {isSmallScreen && user?.firstName && (
                <Typography variant={isVerySmallScreen ? "h6" : "h5"}>
                  Hello, {user.firstName}!
                </Typography>
              )}
              <Typography variant={isVerySmallScreen ? "h5" : "h4"}>
                Welcome to your financial journey with Savvy
              </Typography>
            </Stack>
            {regularScreenNavigationArrows}
          </Stack>

          <Box sx={{ width: "100%", mb: 4, background: "#FFF", p: 0, mx: 0 }}>
            <GradientProgressBar
              value={
                (lastCompletedRoadMapStageIndex(orderedRoadmapStages, user) /
                  orderedRoadmapStages.length) *
                100
              }
              sx={{
                height: 8,
                width: "100%",
              }}
            />
          </Box>
        </Box>
        <Stack
          ref={clientRoadmapScrollRef}
          width="auto"
          direction={isSmallScreen ? "column" : "row"}
          spacing={cardSpacing}
          className="client-roadmap-scroll-container"
          sx={cardWrapperStyles}
        >
          {orderedRoadmapStages.map((roadmapStage, index) => (
            <Box
              ref={index === lastCompletedRoadmapStage ? scrollRef : null}
              key={roadmapStage}
              className={`${roadmapStage}-card`}
              alignSelf="flex-start"
              sx={{
                px: isSmallScreen ? 2 : 0,
                flex: "0 0 auto",
              }}
            >
              <RoadmapStageCard
                disabled={index < lastCompletedRoadmapStage}
                isCurrentStage={index === lastCompletedRoadmapStage}
                taskFrags={tasks}
                roadmapStage={roadmapStage}
                numStages={orderedRoadmapStages.length}
                stageNumber={index}
                width={
                  isSmallScreen ? undefined : DEFAULT_ROADMAP_STAGE_CARD_WIDTH
                }
              />
            </Box>
          ))}
        </Stack>
      </Box>
      <style>
        {/* Hide scrollbar */}
        {`
          .client-roadmap-scroll-container::-webkit-scrollbar {
            display: none;
          }
      `}
      </style>
    </>
  );
}
