import { RefObject } from "react";

export default function scrollRefIntoView(
  ref: RefObject<HTMLElement>,
  options?: ScrollIntoViewOptions
) {
  const block = options?.block ?? "center";
  setTimeout(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block,
      ...options,
    });
  }, 200);
}
