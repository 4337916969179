import { Link } from "@mui/material";
import { XLinkProps } from "./types";
import { mapTextColorToPalette, useModifiedStyleForWeb } from "shared/theme";
import { XTypography } from "shared/x-components/x-typography";
import { primaryColors } from "shared/theme";

export function XLink({
  children,
  href,
  rel,
  target = "_blank",
  onClick,
  ...props
}: XLinkProps) {
  const style = useModifiedStyleForWeb(props.style);
  const color = props.color
    ? mapTextColorToPalette[props.color]
    : primaryColors.main;
  return (
    <XTypography {...props} style={{ cursor: "pointer", ...style }}>
      <Link
        href={href}
        onClick={onClick}
        rel={rel}
        target={target}
        style={{ color }}
      >
        {children}
      </Link>
    </XTypography>
  );
}
