import GuidedFlowDoormat from "./guided-flow-doormat";
import { Button, Fade, Slide, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { XCircularLoadingIndicator } from "shared/x-components/x-circular-loading-indicator";
import { FC, ReactElement, useRef, useState } from "react";

type IntroDoormatProps = {
  header: string;
  body: string | ReactElement;
  loading?: boolean;
  error?: boolean;
  onContinue?: () => void;
  buttonText?: string;
};

// Intro to a guided flow
const IntroDoormat: FC<IntroDoormatProps> = (props) => {
  const { header, body, loading, error, onContinue, buttonText } = props;
  const [slide1, setSlide1] = useState(false);
  const [slide2, setSlide2] = useState(false);
  const containerRef = useRef(null);
  const [containerMaxHeight, setContainerMaxHeight] = useState(0);
  const headerRef = useRef(null);
  const slide1Ref = useRef(null);
  const slide2Ref = useRef(null);

  if (loading) {
    return (
      <GuidedFlowDoormat>
        <XCircularLoadingIndicator />
      </GuidedFlowDoormat>
    );
  }

  if (error) {
    return (
      <GuidedFlowDoormat>
        <Typography>Something went wrong. Please try again.</Typography>
      </GuidedFlowDoormat>
    );
  }

  return (
    <GuidedFlowDoormat maxWidth={543}>
      <>
        <Box
          ref={containerRef}
          sx={{
            transition: "max-height 500ms ease-out",
            maxHeight: containerMaxHeight,
          }}
        >
          <Slide
            direction="up"
            in
            timeout={1000}
            ref={headerRef}
            container={containerRef.current}
            mountOnEnter
            unmountOnExit
            onEntering={() => {
              setContainerMaxHeight(
                containerMaxHeight +
                  // @ts-expect-error ts ref error
                  (headerRef ? headerRef.current.clientHeight : 0)
              );
            }}
            onEntered={() => {
              setSlide1(true);
            }}
          >
            <Box>
              <Typography textAlign="center" variant="h2">
                {header}
              </Typography>
            </Box>
          </Slide>
          {slide1 && (
            <Fade in={slide1} timeout={1000}>
              <div>
                <Slide
                  direction="up"
                  in
                  ref={slide1Ref}
                  timeout={1000}
                  appear={slide1}
                  onEntering={() => {
                    setContainerMaxHeight(
                      containerMaxHeight +
                        // @ts-expect-error ts ref error
                        (slide1Ref ? slide1Ref.current.clientHeight : 0)
                    );
                  }}
                  onEntered={() => {
                    setSlide2(true);
                  }}
                  container={containerRef.current}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box sx={{ my: 2 }}>
                    <Typography
                      textAlign="center"
                      variant="body1"
                      color="textSecondary"
                      component="div"
                    >
                      {body}
                    </Typography>
                  </Box>
                </Slide>
              </div>
            </Fade>
          )}
          {slide2 && (
            <Fade in={slide2} timeout={1000}>
              <Box sx={{ my: 3 }}>
                <Slide
                  direction="up"
                  in
                  ref={slide2Ref}
                  timeout={1000}
                  appear={slide2}
                  onEntering={() => {
                    setContainerMaxHeight(
                      containerMaxHeight +
                        // @ts-expect-error ts ref error
                        (slide2Ref ? slide2Ref.current.clientHeight : 0)
                    );
                  }}
                  container={containerRef.current}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      variant="outlined"
                      onClick={onContinue}
                      size="large"
                    >
                      {buttonText}
                    </Button>
                  </Box>
                </Slide>
              </Box>
            </Fade>
          )}
        </Box>
      </>
    </GuidedFlowDoormat>
  );
};

export default IntroDoormat;
