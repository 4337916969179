import { DateTime } from "luxon";
import {
  SalesforceActivityHistoryItem,
  SalesforceActivityItem,
  isCompletedCampaign,
  isDeftSalesSMS,
  isEmailMessage,
  isEvent,
  isNote,
  isSalesforceActivityHistory,
  isTask,
} from "./salesforce/activity";
import parsePhoneNumber from "libphonenumber-js";

// TODO: consider removing unless we use for heldaway holdings
export function formatDollarAmount(amountRaw: string, showDecimal?: boolean) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    minimumFractionDigits: showDecimal ? 2 : 0,
    maximumFractionDigits: showDecimal ? 5 : 0,
  });

  return formatter.format(Number(amountRaw));
}

// Allow for leading 0s
function minimumIntegerDigits(amountRaw: string) {
  if (!amountRaw.startsWith("0")) {
    return undefined;
  }
  const dotIndex = amountRaw.indexOf(".");
  const hasDecimal = dotIndex !== -1;
  return dotIndex === 0 ? 1 : hasDecimal ? dotIndex : amountRaw.length;
}

export function formatManualDollarAmountEntry(
  amountRaw?: string,
  opts?: { minimumFractionDigits?: number }
) {
  if (!amountRaw) {
    return "";
  }

  const firstDotIndex = amountRaw.indexOf(".");
  const dotIndex = amountRaw.lastIndexOf(".");
  const hasDecimal = dotIndex !== -1;
  const hasMoreThan2Decimals =
    dotIndex > 0 && dotIndex === amountRaw.length - 4;

  let amount = amountRaw;

  if (firstDotIndex !== dotIndex) {
    amount = amount.slice(0, firstDotIndex);
  }

  amount = hasMoreThan2Decimals ? amount.slice(0, amount.length - 1) : amount;

  const hasTrailingDecimal = dotIndex === amount.length - 1;
  const hasTrailing0 =
    hasDecimal && amount.endsWith("0") && dotIndex === amount.length - 2;

  const hasTrailingX0 =
    hasDecimal && amount.endsWith("0") && dotIndex === amount.length - 3;

  let defaultMinimumFractionDigits = 0;
  if (hasTrailing0) {
    defaultMinimumFractionDigits = 1;
  }
  if (hasTrailingX0) {
    defaultMinimumFractionDigits = 2;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    minimumIntegerDigits: minimumIntegerDigits(amount),
    minimumFractionDigits:
      opts?.minimumFractionDigits || defaultMinimumFractionDigits,
    maximumFractionDigits: 2,
  });
  const formattedAmount = formatter.format(Number(amount));

  if (hasTrailingDecimal) {
    return `${formattedAmount}.`;
  }
  return formattedAmount;
}

export function formatSSN(ssn: string, blur: boolean) {
  let fullSSN = ssn.replace(/\D/g, "");
  if (blur) {
    const exedOutNumbers = fullSSN.substring(0, 5).replace(/[0-9]/g, "•");
    fullSSN = exedOutNumbers + fullSSN.substring(5, 9);
  }

  let formattedSSN = "";
  if (fullSSN.length > 3 && fullSSN.length < 6) {
    formattedSSN += `${fullSSN.substring(0, 3)}-`;
    fullSSN = fullSSN.substring(3);
  }
  if (fullSSN.length > 5) {
    formattedSSN += `${fullSSN.substring(0, 3)}-`;
    formattedSSN += `${fullSSN.substring(3, 5)}-`;
    fullSSN = fullSSN.substring(5);
  }
  formattedSSN += fullSSN;
  return formattedSSN.substring(0, 11);
}

export function formatPhoneNumber(phoneNumber: string) {
  if (isValidE164Format(phoneNumber)) {
    return parsePhoneNumber(phoneNumber)?.formatNational() || phoneNumber;
  }

  const fullNumber = phoneNumber.replace(/\D/g, "");
  if (fullNumber.length === 0) return fullNumber;
  if (fullNumber.length < 4) {
    return fullNumber;
  }
  if (fullNumber.length < 7) {
    return `(${fullNumber.slice(0, 3)}) ${fullNumber.slice(3)}`;
  }
  return `(${fullNumber.slice(0, 3)}) ${fullNumber.slice(
    3,
    6
  )}-${fullNumber.slice(6, 10)}`;
}

function isValidE164Format(phoneNumber: string) {
  const regex = /^\+[1-9]\d{10,14}$/;
  return regex.test(phoneNumber);
}

type THouseholdBase = {
  name?: string;
  users?: { firstName?: string; lastName?: string }[];
};
export function formatHouseholdName<THousehold extends THouseholdBase>(
  household: THousehold | null | undefined
) {
  // If there is a custom household name (ie; not "Allison Chan Household")
  if (!household?.name?.trim().toLowerCase().endsWith("household")) {
    return household?.name;
  }
  const users = household.users ?? [];
  // Only display non custom household name for multiple client household
  if (users.length > 1 && household.name.includes("&")) {
    return household.name.replace("Household", "");
  }
  return;
}

export function formatLargeDollarAmount(amount: number) {
  if (amount >= 1000000000) {
    const billion = (amount / 1000000000).toFixed(
      amount >= 10000000000 ? 0 : 1
    );
    return `$${billion}b`;
  } else if (amount >= 1000000) {
    const million = (amount / 1000000).toFixed(amount >= 10000000 ? 0 : 1);
    return `$${million}m`;
  } else if (amount >= 1000) {
    const thousand = (amount / 1000).toFixed(amount >= 10000 ? 0 : 1);
    return `$${thousand}k`;
  }
  return `$${amount.toFixed(0)}`;
}

export function formattedMonthDay(date: string) {
  const dateSplit = date.substring(0, 10).split("-");
  const monthNumber = dateSplit[1];
  const day = dateSplit[2];
  const d = new Date();
  d.setMonth(parseInt(monthNumber) - 1);
  const month = d.toLocaleString("en-US", { month: "short" });
  return `${month} ${day}`;
}

export function formatDateStringExcludeCurrentYear(dateString: string) {
  const date = DateTime.fromISO(dateString).toLocaleString(DateTime.DATE_MED);
  if (date.includes(new Date().getFullYear().toString())) {
    return date.split(",")[0];
  }
  return date;
}

export function formatDateWithTime(dateString: string) {
  const dayOfTimestamp = formatDateStringExcludeCurrentYear(dateString);
  const timeOfTimestamp = DateTime.fromISO(dateString).toLocaleString(
    DateTime.TIME_SIMPLE
  );
  const formatDateWithTime = `${dayOfTimestamp} at ${timeOfTimestamp.toLowerCase()}`;
  return formatDateWithTime;
}

function formatDateForSalesforceActivityHistory(
  activity: SalesforceActivityHistoryItem
) {
  if (activity.ActivityType === "SMS" || isDeftSalesSMS(activity)) {
    const day = formatDateStringExcludeCurrentYear(activity.CreatedDate);
    return day;
  }
  if (activity.Dialpad__CallId__c && activity.Dialpad__Call_End_Time__c) {
    const dayOfCall = formatDateStringExcludeCurrentYear(
      activity.Dialpad__Call_End_Time__c
    );

    const callEndTime = DateTime.fromISO(
      activity.Dialpad__Call_End_Time__c
    ).toLocaleString(DateTime.TIME_SIMPLE);
    const callStartTime = DateTime.fromISO(activity.Dialpad__Call_End_Time__c)
      .minus({ minutes: activity.DurationInMinutes || 0 })
      .toLocaleString(DateTime.TIME_SIMPLE);

    const formattedCallTime =
      callStartTime === callEndTime
        ? `${dayOfCall} at ${callStartTime.toLowerCase()}`
        : `${dayOfCall} at ${
            callStartTime.split(" ")[0]
          }-${callEndTime.toLowerCase()}`;

    return formattedCallTime;
  } else if (activity.ActivitySubtype === "Call") {
    const dayOfCall = formatDateStringExcludeCurrentYear(
      activity.ActivityDate || activity.CreatedDate
    );
    return dayOfCall;
  } else if (activity.ActivitySubtype === "Task") {
    const dayOfTask = formatDateStringExcludeCurrentYear(
      activity.CompletedDateTime || activity.CreatedDate
    );
    const taskTime = DateTime.fromISO(
      activity.CompletedDateTime || activity.CreatedDate
    ).toLocaleString(DateTime.TIME_SIMPLE);
    const taskDateTimeString = activity.CompletedDateTime
      ? `${dayOfTask} at ${taskTime.toLowerCase()}`
      : formatDateStringExcludeCurrentYear(activity.CreatedDate);
    return taskDateTimeString;
  }
  // This case should not happen!
  return activity.ActivityDate || "";
}

export function formatDateForAdvisorDashboard<T extends SalesforceActivityItem>(
  activity: T
) {
  if (isSalesforceActivityHistory(activity)) {
    return formatDateForSalesforceActivityHistory(activity);
  } else if (isEmailMessage(activity)) {
    return formatDateWithTime(activity.MessageDate);
  } else if (isNote(activity)) {
    const dayOfNote = formatDateStringExcludeCurrentYear(activity.createdAt);
    const timeOfNote = DateTime.fromISO(activity.createdAt).toLocaleString(
      DateTime.TIME_SIMPLE
    );
    const formattedNoteTimestamp = `${dayOfNote} at ${timeOfNote.toLowerCase()}`;
    return formattedNoteTimestamp;
  } else if (isTask(activity)) {
    const dayOfTask = formatDateStringExcludeCurrentYear(activity.CreatedDate);
    return `Created ${dayOfTask}`;
  } else if (isEvent(activity)) {
    if (activity.StartDateTime || activity.ActivityDateTime) {
      return formatDateStringExcludeCurrentYear(
        activity.StartDateTime || activity.ActivityDateTime || ""
      );
    }
    return "";
  } else if (isCompletedCampaign(activity)) {
    // return formateDateString(activity.completedDate || "");
  }
  return "";
}

export function snakeCaseToSentenceCase(inputString: string) {
  const spacedString = inputString.replace(/_/g, " ");
  return spacedString.charAt(0).toUpperCase() + spacedString.slice(1);
}

// 2023-04-01T00:00:00Z
export function formatTimestampToRelative(timestamp: string | undefined) {
  if (!timestamp) {
    return "";
  }
  const dt = DateTime.fromISO(timestamp, { zone: "utc" });
  const now = DateTime.now();
  const diffInYears = now.diff(dt, "years").years;
  if (diffInYears > 1) {
    return dt.toFormat("MMM d, yyyy");
  }
  return dt.toRelative();
}

export function formatTimestampToRelativeWithoutAgo(
  timestamp: string | undefined
) {
  const relative_date = formatTimestampToRelative(timestamp);
  return relative_date?.replace(" ago", "");
}

export function formatTimestampToMonthAndDay(timestamp: string | undefined) {
  if (!timestamp) {
    return "";
  }
  const dt = DateTime.fromISO(timestamp, { zone: "utc" });
  return dt.toFormat("MMM d");
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const month = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    date
  );
  const day = date.getDate();
  const year = date.getFullYear();
  const formattedDate = `${month} ${day}, ${year}`;
  return formattedDate;
}

export function toTitleCase(str: string) {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function formatDatetoUSStyle(date: Date | null) {
  if (!date) return "";
  const day = `0${date.getDate()}`.slice(-2);
  const month = `0${date.getMonth() + 1}`.slice(-2);
  const year = date.getFullYear().toString();
  return `${month}/${day}/${year}`;
}

export function formatDateToYYYYMMDD(date: Date): string {
  const dt = DateTime.fromJSDate(date);
  return dt.toFormat("yyyyMMdd");
}

export function formatDateBasedOnYear(dateString: string) {
  const inputDate = DateTime.fromISO(dateString);
  const now = DateTime.now();

  if (inputDate.year === now.year) {
    return inputDate.toFormat("LLL d");
  }
  return inputDate.toFormat("MM/dd/yy");
}

export function truncateString(str: string, maxLength: number) {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength - 3)}...`;
  }
  return str;
}

export function formatDateToMMDDYY(dateString: string) {
  const inputDate = DateTime.fromISO(dateString);
  return inputDate.toFormat("MM/dd/yy");
}

export function formatLargeUSDPrecision(
  value: number,
  precision: number
): string {
  let counter = 0;
  let formattedValue = Math.abs(value);
  const units = ["", "k", "m", "b"];
  while (formattedValue > 1000) {
    counter++;
    formattedValue /= 1000;
  }

  const roundedValue =
    formattedValue % 1 !== 0
      ? formattedValue.toPrecision(precision)
      : formattedValue.toString();

  return value < 0
    ? `-$${roundedValue}${units[counter]}`
    : `$${roundedValue}${units[counter]}`;
}
