import { Box, Grid } from "@mui/material";
import { ReactNode } from "react";
import { GUIDED_FLOW_MAX_WIDTH } from "src/constants/guided-flow";
import { XSavvyFooter } from "shared/x-components/x-savvy-footer";
import { XSavvyLogoHeader } from "shared/x-components/x-savvy-logo-header";

// Used for intros and outros to a guided flow
export default function GuidedFlowDoormat({
  maxWidth = GUIDED_FLOW_MAX_WIDTH,
  maxHeight,
  children,
}: {
  maxWidth?: number;
  maxHeight?: number;
  children: ReactNode;
}) {
  return (
    <Box>
      <Box
        sx={{
          top: "30px",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute", // TODO: Remove this position, and make everything regular flex display
        }}
      >
        <XSavvyLogoHeader />
      </Box>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "100vh", position: "relative" }}
      >
        <Box sx={{ maxWidth, maxHeight, my: 6 }}>{children}</Box>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            left: 0,
            pt: 0,
            bottom: 0,
            pb: 4,
          }}
        >
          <XSavvyFooter />
        </Box>
      </Grid>
    </Box>
  );
}
