import {
  AccountSubtypeName as AccountSubtypeNameEnum,
  AccountTypeName as AccountTypeNameEnum,
  AssetClassName,
  AssetSubclassName,
  LiabilityTypeName,
} from "shared/generated/graphql";
import { AccountTypeName } from "shared/generated/string_unions/graphql";
import defaultGuard from "shared/utils/default-guard";

export const PLAID_OAUTH_AUTH_LOCAL_STORAGE_KEY = "plaid-link-token";
export const PLAID_OAUTH_AUTH_REDIRECT_PAGE = "plaid-oauth-redirect-page";

export const enum ItemType {
  BANK_ACCOUNT = "BANK_ACCOUNT",
  INVESTMENT_ACCOUNT = "INVESTMENT_ACCOUNT",
  REAL_ESTATE = "REAL_ESTATE",
  COMPANY_EQUITY = "COMPANY_EQUITY",
  OTHER_ACCOUNT = "OTHER_ACCOUNT",
  OTHER_ASSET = "OTHER_ASSET",
  OTHER_LIABILITY = "OTHER_LIABILITY",
}

export const ASSET_CLASS_DISPLAY_NAMES: Record<AssetClassName, string> = {
  [AssetClassName.Collectible]: "Collectible",
  [AssetClassName.CompanyEquity]: "Company Equity",
  [AssetClassName.InsurancePolicy]: "Insurance Policy",
  [AssetClassName.RealEstate]: "Real Estate",
  [AssetClassName.Vehicle]: "Vehicle",
  [AssetClassName.Other]: "Other Asset",
};

export const ASSET_CLASS_MANUAL_ADD = [
  AssetClassName.InsurancePolicy,
  AssetClassName.Collectible,
  AssetClassName.Vehicle,
  AssetClassName.Other,
];

export const ASSET_SUBCLASS_DISPLAY_NAMES: Record<AssetSubclassName, string> = {
  [AssetSubclassName.InvestmentProperty]: "Investment property",
  [AssetSubclassName.Other]: "Other",
  [AssetSubclassName.PrimaryResidence]: "Primary residence",
  [AssetSubclassName.SecondaryResidence]: "Secondary residence",
  [AssetSubclassName.Boat]: "Boat",
  [AssetSubclassName.Car]: "Car",
  [AssetSubclassName.Antique]: "Antique",
  [AssetSubclassName.Art]: "Art",
  [AssetSubclassName.Jewelry]: "Jewelry",
  [AssetSubclassName.Residence]: "Residence",
  [AssetSubclassName.NonResidence]: "Non-residence",
};

export const LIABILITY_TYPE_DISPLAY_NAME: Record<LiabilityTypeName, string> = {
  [LiabilityTypeName.Auto]: "Auto",
  [LiabilityTypeName.Commercial]: "Commercial",
  [LiabilityTypeName.Consumer]: "Consumer",
  [LiabilityTypeName.General]: "General",
  [LiabilityTypeName.HomeEquity]: "Home Equity",
  [LiabilityTypeName.LineOfCredit]: "Line of Credit",
  [LiabilityTypeName.Mortgage]: "Mortgage",
  [LiabilityTypeName.Other]: "Other Liability",
  [LiabilityTypeName.Student]: "Student",
};
export function isLiabilityTypeName(type: string): type is LiabilityTypeName {
  return Object.values(LiabilityTypeName).includes(type as LiabilityTypeName);
}
export function isAccountTypeName(type: string): type is AccountTypeNameEnum {
  return Object.values(AccountTypeNameEnum).includes(
    type as AccountTypeNameEnum
  );
}

export const LIABILITY_TYPES_MANUAL_ADD = [
  LiabilityTypeName.Auto,
  LiabilityTypeName.Commercial,
  LiabilityTypeName.Consumer,
  LiabilityTypeName.LineOfCredit,
  LiabilityTypeName.Student,
  LiabilityTypeName.Other,
];

/**
 * @deprecated use the accountSubtypeDisplayName function instead
 */
export const DEPRECATED_ACCOUNT_SUBTYPE_DISPLAY_NAMES: Record<
  AccountSubtypeNameEnum | "undefined" | "null",
  string
> = {
  [AccountSubtypeNameEnum.BeneficiaryIra]: "Beneficiary IRA",
  [AccountSubtypeNameEnum.CashManagement]: "Cash Management",
  [AccountSubtypeNameEnum.Cd]: "Certificate of Deposit",
  [AccountSubtypeNameEnum.Checking]: "Checking",
  [AccountSubtypeNameEnum.CompanyDaf]: "Company DAF",
  [AccountSubtypeNameEnum.ContributoryIra]: "Contributory IRA",
  [AccountSubtypeNameEnum.Corporation]: "Corporation",
  [AccountSubtypeNameEnum.DefinedBenefitPlan]: "Defined Benefit Plan",
  [AccountSubtypeNameEnum.EducationIra]: "Education IRA",
  [AccountSubtypeNameEnum.Ebt]: "EBT",
  [AccountSubtypeNameEnum.Hsa]: "Health Savings Account",
  [AccountSubtypeNameEnum.MoneyMarket]: "Money Market",
  [AccountSubtypeNameEnum.Paypal]: "Paypal",
  [AccountSubtypeNameEnum.PledgedAssetLine]: "Pledged asset line",
  [AccountSubtypeNameEnum.Prepaid]: "Prepaid",
  [AccountSubtypeNameEnum.Savings]: "Savings",
  [AccountSubtypeNameEnum.CreditCard]: "Credit card",
  [AccountSubtypeNameEnum.PaypalCreditCard]: "Paypal Credit Card",
  [AccountSubtypeNameEnum.Auto]: "Auto",
  [AccountSubtypeNameEnum.Business]: "Business",
  [AccountSubtypeNameEnum.Commercial]: "Commercial",
  [AccountSubtypeNameEnum.Construction]: "Construction",
  [AccountSubtypeNameEnum.Consumer]: "Consumer",
  [AccountSubtypeNameEnum.HomeEquity]: "Home equity",
  [AccountSubtypeNameEnum.LineOfCredit]: "Line of Credit",
  [AccountSubtypeNameEnum.Loan]: "Loan",
  [AccountSubtypeNameEnum.Mortgage]: "Mortgage",
  [AccountSubtypeNameEnum.Other]: "Other",
  [AccountSubtypeNameEnum.Overdraft]: "Overdraft",
  [AccountSubtypeNameEnum.Student]: "Student",
  [AccountSubtypeNameEnum.Plan401a]: "401a",
  [AccountSubtypeNameEnum.Plan401k]: "401k",
  [AccountSubtypeNameEnum.Plan403b]: "403b",
  [AccountSubtypeNameEnum.Plan457b]: "457b",
  [AccountSubtypeNameEnum.Plan529]: "529",
  [AccountSubtypeNameEnum.Brokerage]: "Brokerage",
  [AccountSubtypeNameEnum.CashIsa]: "Cash ISA",
  [AccountSubtypeNameEnum.ContributoryRoth]: "Contributory Roth",
  [AccountSubtypeNameEnum.Foundation]: "Foundation",
  [AccountSubtypeNameEnum.EducationSavingsAccount]: "Education Savings Account",
  [AccountSubtypeNameEnum.FixedAnnuity]: "Fixed Annuity",
  [AccountSubtypeNameEnum.GstExempt]: "GST Exempt",
  [AccountSubtypeNameEnum.Gic]: "Guaranteed Investment Certificate (GIC)",
  [AccountSubtypeNameEnum.HealthReimbursementArrangement]:
    "Health Reimbursement Arrangement",
  [AccountSubtypeNameEnum.Ira]: "IRA",
  [AccountSubtypeNameEnum.IraRollover]: "IRA Rollover",
  [AccountSubtypeNameEnum.InheritedIra]: "Inherited IRA",
  [AccountSubtypeNameEnum.Isa]: "ISA",
  [AccountSubtypeNameEnum.Joint]: "Joint",
  [AccountSubtypeNameEnum.JointTenant]: "Joint Tenant",
  [AccountSubtypeNameEnum.Jtwros]: "JTWROS",
  [AccountSubtypeNameEnum.Keogh]: "Keogh",
  [AccountSubtypeNameEnum.Lif]: "Life Income Fund (LIF)",
  [AccountSubtypeNameEnum.LifeInsurance]: "Life Insurance",
  [AccountSubtypeNameEnum.Lira]: "LIRA",
  [AccountSubtypeNameEnum.Lrif]: "LRIF",
  [AccountSubtypeNameEnum.Lrsp]: "LRSP",
  [AccountSubtypeNameEnum.MaritalTrust]: "Marital Trust",
  [AccountSubtypeNameEnum.MutualFund]: "Mutual Fund",
  [AccountSubtypeNameEnum.NonTaxableBrokerageAccount]:
    "Non-taxable Brokerage Account",
  [AccountSubtypeNameEnum.OtherAnnuity]: "Other Annuity",
  [AccountSubtypeNameEnum.OtherInsurance]: "Other Insurance",
  [AccountSubtypeNameEnum.Pension]: "Pension",
  [AccountSubtypeNameEnum.PersonalChoiceRetirementAccount]:
    "Personal Choice Retirement Account",
  [AccountSubtypeNameEnum.PrivateEquity]: "Private Equity",
  [AccountSubtypeNameEnum.Prif]: "PRIF",
  [AccountSubtypeNameEnum.ProfitSharingPlan]: "Profit Sharing Plan",
  [AccountSubtypeNameEnum.Qshr]: "QSHR",
  [AccountSubtypeNameEnum.Rdsp]: "RDSP",
  [AccountSubtypeNameEnum.Resp]: "RESP",
  [AccountSubtypeNameEnum.Retirement]: "Retirement",
  [AccountSubtypeNameEnum.RevocableTrust]: "Revocable Trust",
  [AccountSubtypeNameEnum.Rlif]: "RLIF",
  [AccountSubtypeNameEnum.Roth]: "Roth IRA",
  [AccountSubtypeNameEnum.Roth_401k]: "Roth 401k",
  [AccountSubtypeNameEnum.Rrif]: "RRIF",
  [AccountSubtypeNameEnum.Rrsp]: "RRSP",
  [AccountSubtypeNameEnum.Sarsep]: "SARSEP",
  [AccountSubtypeNameEnum.SepIra]: "SEP IRA",
  [AccountSubtypeNameEnum.SimpleIra]: "Simple IRA",
  [AccountSubtypeNameEnum.Sipp]: "SIPP",
  [AccountSubtypeNameEnum.StockPlan]: "Stock Plan",
  [AccountSubtypeNameEnum.Tfsa]: "TTFA",
  [AccountSubtypeNameEnum.Trust]: "Trust",
  [AccountSubtypeNameEnum.Ugma]: "UGMA",
  [AccountSubtypeNameEnum.Utma]: "UTMA",
  [AccountSubtypeNameEnum.VariableAnnuity]: "Variable Annuity",
  [AccountSubtypeNameEnum.HotWallet]: "Hot wallet",
  [AccountSubtypeNameEnum.ColdWallet]: "Cold wallet",
  [AccountSubtypeNameEnum.HardwareWallet]: "Hardware Wallet",
  [AccountSubtypeNameEnum.Exchange]: "Exchange",
  [AccountSubtypeNameEnum.Private]: "Private",
  [AccountSubtypeNameEnum.Partnership]: "Partnership",
  [AccountSubtypeNameEnum.Public]: "Public",
  [AccountSubtypeNameEnum.RealEstate]: "Real Estate",
  [AccountSubtypeNameEnum.Spouse]: "Spouse",
  [AccountSubtypeNameEnum.TransferOnDeath]: "Transfer on Death",
  [AccountSubtypeNameEnum.Iso]: "ISO",
  [AccountSubtypeNameEnum.Nso]: "NSO",
  [AccountSubtypeNameEnum.Rsa]: "RSA",
  [AccountSubtypeNameEnum.Rsu]: "RSU",
  [AccountSubtypeNameEnum.UniformTransfersToMinorsAccount]:
    "Uniform Transfers to Minors Account",
  [AccountSubtypeNameEnum.CommunityProperty]: "Community Property",
  [AccountSubtypeNameEnum.Conservatorship]: "Conservatorship",
  [AccountSubtypeNameEnum.ConversionRoth]: "Conversion Roth",
  [AccountSubtypeNameEnum.Custodial]: "Custodial",
  [AccountSubtypeNameEnum.DefinedContributionPlan]: "Defined Contribution Plan",
  [AccountSubtypeNameEnum.DefinedPension]: "Defined Pension",
  [AccountSubtypeNameEnum.Estate]: "Estate",
  [AccountSubtypeNameEnum.Guardianship]: "Guardianship",
  [AccountSubtypeNameEnum.InvalidType]: "Other",
  [AccountSubtypeNameEnum.MoneyPurchasePensionPlan]:
    "Money Purchase Pension Plan",
  [AccountSubtypeNameEnum.NonProfit]: "Non-Profit",
  [AccountSubtypeNameEnum.OptionalRetirementProgram]:
    "Optional Retirement Program",
  [AccountSubtypeNameEnum.RetirementPlan]: "Retirement Plan",
  [AccountSubtypeNameEnum.SimplifiedEmployeePensionPlan]:
    "Simplified Employee Pension Plan",
  [AccountSubtypeNameEnum.SoleAndSeparateProperty]:
    "Sole and Separate Property",
  [AccountSubtypeNameEnum.TaxShelteredAccount]: "Tax Sheltered Account",
  undefined: "Other",
  null: "Other",
};

/*
 * @deprecated use the otherAccountDisplayName function instead
 */
export const DEPRECATED_OTHER_ACCOUNT_DISPLAY_NAME: Record<
  AccountTypeNameEnum | "undefined" | "null",
  string | undefined | null
> = {
  [AccountTypeNameEnum.Depository]: "Other bank account",
  [AccountTypeNameEnum.Investment]: "Other investment account",
  [AccountTypeNameEnum.Credit]: "Other credit account",
  [AccountTypeNameEnum.Insurance]: "Other insurance account",
  [AccountTypeNameEnum.Cryptocurrency]: "Other Crypto account",
  [AccountTypeNameEnum.Loan]: "Other loan account",
  [AccountTypeNameEnum.Other]: "Other",
  [AccountTypeNameEnum.EmployerEquity]: "Other employer equity account",
  [AccountTypeNameEnum.FundInvestment]: "Other fund investment account",
  [AccountTypeNameEnum.PrivateInvestment]: "Other private investment account",
  undefined: "Other",
  null: "Other",
};
export function otherAccountDisplayName(
  accountTypeName: AccountTypeName | null | undefined
) {
  switch (accountTypeName) {
    case "depository":
      return "Other bank account";
    case "investment":
      return "Other investment account";
    case "credit":
      return "Other credit account";
    case "insurance":
      return "Other insurance account";
    case "cryptocurrency":
      return "Other Crypto account";
    case "loan":
      return "Other loan account";
    case "other":
      return "Other";
    case "employer_equity":
      return "Other employer equity account";
    case "fund_investment":
      return "Other fund investment account";
    case "private_investment":
      return "Other private investment account";
    case undefined:
    case null:
      return "Other";
    default:
      defaultGuard(accountTypeName);
      return "Other";
  }
}

export const REAL_ESTATE_SUBCLASSES_MANUAL_ADD = [
  AssetSubclassName.Residence,
  AssetSubclassName.NonResidence,
];

// TODO: auto-generate groupings from the backend
export const DEPOSITORY_SUB_TYPES_MANUAL_ADD = [
  AccountSubtypeNameEnum.Checking,
  AccountSubtypeNameEnum.Savings,
  AccountSubtypeNameEnum.MoneyMarket,
  AccountSubtypeNameEnum.Cd,
  AccountSubtypeNameEnum.Hsa,
  AccountSubtypeNameEnum.Other,
];

export const CREDIT_SUBTYPES = [
  AccountSubtypeNameEnum.CreditCard,
  AccountSubtypeNameEnum.PaypalCreditCard,
];

export const LOAN_SUBTYPES = [
  AccountSubtypeNameEnum.Auto,
  AccountSubtypeNameEnum.Business,
  AccountSubtypeNameEnum.Commercial,
  AccountSubtypeNameEnum.Construction,
  AccountSubtypeNameEnum.Consumer,
  AccountSubtypeNameEnum.HomeEquity,
  AccountSubtypeNameEnum.LineOfCredit,
  AccountSubtypeNameEnum.Loan,
  AccountSubtypeNameEnum.Mortgage,
  AccountSubtypeNameEnum.Other,
  AccountSubtypeNameEnum.Overdraft,
  AccountSubtypeNameEnum.Student,
];

export const INVESTMENT_SUB_TYPES_MANUAL_ADD = [
  AccountSubtypeNameEnum.Plan401k,
  AccountSubtypeNameEnum.Brokerage,
  AccountSubtypeNameEnum.Ira,
  AccountSubtypeNameEnum.Roth,
  AccountSubtypeNameEnum.MutualFund,
  AccountSubtypeNameEnum.Plan529,
  AccountSubtypeNameEnum.Plan403b,
  AccountSubtypeNameEnum.Other,
];
