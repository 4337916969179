import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import GuidedFlowStepDoneIcon from "../../icons/custom/done";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";
import { useAuth } from "shared/hooks/use-auth";
import assert from "assert";
import { isSignedRequiredOnboardingTask } from "src/utils/client-roadmap";

const ClientOnboardingTaskStatusFragment = graphql(/* GraphQL */ `
  fragment ClientOnboardingTaskStatus on Task {
    maybeDocusignSigned
    signatureId
    status
  }
`);

export const HouseholdOnboardingCallToActionFragment = graphql(
  /* GraphQL */ `
    fragment HouseholdOnboardingCallToAction on Task {
      user {
        id
        isPrimaryClient
        firstName
        lastName
      }
      type
      status
      signatureId
      maybeDocusignSigned
      ...ClientOnboardingTaskStatus
    }
  `,
  [ClientOnboardingTaskStatusFragment]
);

type Props = {
  requriedOnboardingTaskFrags: FragmentOf<
    typeof HouseholdOnboardingCallToActionFragment
  >[];
};
const HouseholdOnboardingCallToAction = ({
  requriedOnboardingTaskFrags,
}: Props) => {
  const { user: currUser } = useAuth();
  assert(currUser != null, "User is not authenticated");
  const householdOnboardingTasks = requriedOnboardingTaskFrags.map((taskFrag) =>
    unmaskFragment(HouseholdOnboardingCallToActionFragment, taskFrag)
  );
  const primaryClientOnboardingTask = householdOnboardingTasks.find(
    (task) => task.user.isPrimaryClient
  );
  const coClientOnboardingTask = householdOnboardingTasks.find(
    (task) => !task.user.isPrimaryClient
  );
  const primaryDidNotSign = !isSignedRequiredOnboardingTask({
    status: primaryClientOnboardingTask?.status,
    type: primaryClientOnboardingTask?.type,
    maybeDocusignSigned: primaryClientOnboardingTask?.maybeDocusignSigned,
    signatureId: primaryClientOnboardingTask?.signatureId,
  });
  const coClientDidNotSign = !isSignedRequiredOnboardingTask({
    status: coClientOnboardingTask?.status,
    type: coClientOnboardingTask?.type,
    maybeDocusignSigned: coClientOnboardingTask?.maybeDocusignSigned,
    signatureId: coClientOnboardingTask?.signatureId,
  });
  const showOnboardingTodoButton = currUser.isPrimaryClient
    ? primaryDidNotSign
    : coClientDidNotSign;

  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell align="left" style={{ padding: 0 }}>
            <Typography variant="body2" component="span">
              {primaryClientOnboardingTask?.user.firstName}{" "}
              {primaryClientOnboardingTask?.user.lastName}
            </Typography>
          </TableCell>
          <TableCell align="right">
            {primaryClientOnboardingTask != null && (
              <ClientOnboardingTaskStatus
                taskFrag={primaryClientOnboardingTask}
              />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="left" style={{ borderBottom: "none", padding: 0 }}>
            {coClientOnboardingTask?.user.firstName}{" "}
            {coClientOnboardingTask?.user.lastName}
          </TableCell>
          <TableCell align="right" style={{ borderBottom: "none" }}>
            {coClientOnboardingTask != null && (
              <ClientOnboardingTaskStatus taskFrag={coClientOnboardingTask} />
            )}
          </TableCell>
        </TableRow>
        <TableRow>
          {showOnboardingTodoButton && (
            <TableCell
              colSpan={6}
              style={{ borderBottom: "none", paddingLeft: 0, paddingRight: 16 }}
            >
              <Button
                color="primary"
                size="large"
                variant="contained"
                fullWidth
                onClick={() => (window.location.href = "/onboarding")}
              >
                Open
              </Button>{" "}
            </TableCell>
          )}
        </TableRow>
      </TableBody>
    </Table>
  );
};

type ClientOnboardingTaskStatusParams = {
  taskFrag: FragmentOf<typeof ClientOnboardingTaskStatusFragment>;
};
export const ClientOnboardingTaskStatus = ({
  taskFrag,
}: ClientOnboardingTaskStatusParams) => {
  const task = unmaskFragment(ClientOnboardingTaskStatusFragment, taskFrag);
  const isNotRequiredTask =
    task.status !== "in_progress" && task.status !== "todo";
  const hasSigned =
    isNotRequiredTask || task.maybeDocusignSigned || task.signatureId != null;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      {hasSigned && <GuidedFlowStepDoneIcon />}
      <Typography variant="body2" color="textSecondary" component="span">
        {hasSigned ? "Signed" : "Needs to sign"}
      </Typography>
    </Stack>
  );
};

export default HouseholdOnboardingCallToAction;
