import { Button } from "@mui/material";
import { RoadmapStage } from "src/constants/client-roadmap";

import HouseholdOnboardingCallToAction, {
  HouseholdOnboardingCallToActionFragment,
} from "./household-onboarding-call-to-action";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";
import { isRequiredTaskStatus } from "src/utils/client-roadmap";

export const RoadmapCallToActionFragment = graphql(
  /* GraphQL */ `
    fragment RoadmapCallToAction on Task {
      status
      type
      ...HouseholdOnboardingCallToAction
    }
  `,
  [HouseholdOnboardingCallToActionFragment]
);

const RoadmapCallToAction = ({
  roadmapStage,
  taskFrags,
}: {
  roadmapStage: RoadmapStage;
  taskFrags: FragmentOf<typeof RoadmapCallToActionFragment>[];
}) => {
  const onboardingTaskFrags = taskFrags
    .filter((taskFrag) => {
      const task = unmaskFragment(RoadmapCallToActionFragment, taskFrag);
      return (
        task.type === "onboarding_flow" &&
        isRequiredTaskStatus({ status: task.status })
      );
    })
    .map((taskFrag) => unmaskFragment(RoadmapCallToActionFragment, taskFrag));
  switch (roadmapStage) {
    case RoadmapStage.SIT_BACK_AND_WAIT:
    case RoadmapStage.INTRO_CALL:
    case RoadmapStage.COMPLETED:
      return null;
    case RoadmapStage.INTAKE_FLOW:
      return (
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          onClick={() => (window.location.href = "/welcome")}
        >
          Open
        </Button>
      );
    case RoadmapStage.ACCOUNT_SETUP:
      if (
        taskFrags.filter((taskFrag) => {
          const task = unmaskFragment(RoadmapCallToActionFragment, taskFrag);
          return isRequiredTaskStatus({ status: task.status });
        }).length === 0
      ) {
        return (
          <Button
            color="primary"
            size="large"
            variant="contained"
            disabled
            fullWidth
          >
            In review
          </Button>
        );
      }
      return (
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth
          // TODO: support returning to dashboard after task
          onClick={() => (window.location.href = "/account-setup")}
        >
          Open
        </Button>
      );
    case RoadmapStage.ONBOARDING_FLOW:
      return (
        <HouseholdOnboardingCallToAction
          requriedOnboardingTaskFrags={onboardingTaskFrags}
        />
      );
  }
};

export default RoadmapCallToAction;
