import { useRouter } from "shared/hooks/use-router";
import { useAuth } from "shared/hooks/use-auth";
import { XResetClientIntakeDataFooterButton } from "shared/internal/x-components/x-reset-client-intake-data-footer-button";
import { XTypography } from "shared/x-components/x-typography";
import { XLink } from "shared/x-components/x-link";
import { View } from "react-native";
import { toast } from "shared/x-components/x-toast";
import { useIsSmallScreen } from "shared/hooks/use-is-small-screen";

export function XSavvyFooter() {
  const { logout, isAuthenticated, user } = useAuth();
  const router = useRouter();
  const isSmallScreen = useIsSmallScreen();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      router.push("/login");
    } catch (err) {
      console.error(err);
      toast.error("Unable to logout.");
    }
  };

  return (
    <View
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      {isAuthenticated && (
        <>
          <View
            style={{
              paddingHorizontal: 2,
              paddingBottom: 3,
            }}
          >
            <XLink
              href="#"
              onClick={() => {
                void handleLogout();
              }}
              underline="none"
            >
              <XTypography variant="caption" color="textSecondary">
                Logout
              </XTypography>
            </XLink>
          </View>
          <View style={{ paddingHorizontal: 2 }}>
            <XTypography variant="caption" color="textSecondary">
              •
            </XTypography>
          </View>
        </>
      )}
      {/* Only shown internally */}
      {!isSmallScreen && <XResetClientIntakeDataFooterButton user={user} />}

      <View
        style={{
          paddingHorizontal: 2,
        }}
      >
        <XLink
          color="textSecondary"
          target="_blank"
          underline="none"
          href="https://www.savvywealth.com/terms-of-service"
          variant="caption"
        >
          Terms of Service
        </XLink>
      </View>
      <View
        style={{
          paddingHorizontal: 2,
        }}
      >
        <XTypography variant="caption" color="textSecondary">
          •
        </XTypography>
      </View>
      <View style={{ paddingHorizontal: 2 }}>
        <XTypography variant="caption" color="textSecondary">
          Savvy Wealth Inc.
        </XTypography>
      </View>
    </View>
  );
}
