import { AccountBalance } from "@mui/icons-material";
import { Avatar } from "@mui/material";

export function isBase64URL(imgUrl?: string) {
  if (!imgUrl) return false;
  return !imgUrl.startsWith("http");
}

export function providerImgSrc(providerLogo?: string) {
  if (isBase64URL(providerLogo)) {
    return `data:image/jpeg;base64, ${providerLogo}`;
  }
  return providerLogo;
}

export function renderProviderImg(providerLogo: string, size = 24) {
  if (!providerLogo) {
    return <AccountBalance sx={{ width: size, height: size }} />;
  }

  return (
    <Avatar
      variant="circular"
      sx={{ height: size, width: size }}
      src={providerImgSrc(providerLogo)}
    />
  );
}
