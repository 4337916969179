import {
  CLIENT_STAGE_TO_ROADMAP_STAGE,
  RoadmapStage,
} from "src/constants/client-roadmap";
import { JWTContextUser } from "shared/api/user-api";
import { ClientStage } from "shared/generated/graphql";
import {
  ProviderAccountSource,
  TaskStatus,
  TaskTypeName,
} from "shared/generated/string_unions/graphql";

export function roadmapStageTitle(roadmapStage: RoadmapStage) {
  switch (roadmapStage) {
    case RoadmapStage.INTRO_CALL:
      return "Intro call";
    case RoadmapStage.INTAKE_FLOW:
      return "Complete your welcome forms";
    case RoadmapStage.ACCOUNT_SETUP:
      return "Prepare your accounts";
    case RoadmapStage.ONBOARDING_FLOW:
      return "Sign account opening forms";
    case RoadmapStage.SIT_BACK_AND_WAIT:
      return "Sit back and relax!";
    default:
      return "";
  }
}

export function lastCompletedRoadMapStageIndex(
  roadmap: RoadmapStage[],
  clientUser: JWTContextUser | null
) {
  if (clientUser?.clientStage == null) {
    return 0;
  } else if (clientUser.clientStage === ClientStage.FullyOnboarded) {
    return roadmap.length - 1;
  }
  const currentRoadmapStage =
    CLIENT_STAGE_TO_ROADMAP_STAGE[clientUser.clientStage];

  const index = roadmap.indexOf(currentRoadmapStage);
  return index === -1 ? 0 : index;
}

export function showPortfolioSplashScreen(
  clientStage: ClientStage,
  client:
    | { providerAccounts?: { source?: ProviderAccountSource | null }[] | null }
    | null
    | undefined
) {
  return (
    clientStage === ClientStage.PostOnboardingWaitingArea &&
    client?.providerAccounts?.find((pa) => pa.source === "ORION")
  );
}

export function roadmapStageBody(roadmapStage: RoadmapStage) {
  switch (roadmapStage) {
    case RoadmapStage.INTRO_CALL:
      return "On this initial call we will go over Savvy’s offerings and align on how we can serve you.";
    case RoadmapStage.INTAKE_FLOW:
      return "This info helps us understand your financial situation and create a plan for you.";
    case RoadmapStage.ACCOUNT_SETUP:
      return "Share information and documents such as account statements to help your advisor prepare your move to Savvy.";
    case RoadmapStage.ONBOARDING_FLOW:
      return "This will officially make you a Savvy customer and allow us to begin working together!";
    case RoadmapStage.SIT_BACK_AND_WAIT:
      return "Your advisor will get in touch once your account has been funded!";
    case RoadmapStage.COMPLETED:
      return "Congratulations! Your accounts are funded and ready to go.";
  }
}

export const roadmapStageToTaskType: Partial<
  Record<RoadmapStage, TaskTypeName>
> = {
  [RoadmapStage.INTAKE_FLOW]: "initial_intake_form",
  [RoadmapStage.ACCOUNT_SETUP]: "account_setup",
  [RoadmapStage.ONBOARDING_FLOW]: "onboarding_flow",
};

type IsRequiredTaskStatusParams = {
  status: TaskStatus | null | undefined;
};
export function isRequiredTaskStatus({ status }: IsRequiredTaskStatusParams) {
  return status !== "hidden" && status !== "cancelled";
}

type IsSignedRequiredOnboardingTaskParams = {
  maybeDocusignSigned: boolean | null | undefined;
  signatureId: string | number | null | undefined;
  status: TaskStatus | null | undefined;
  type: TaskTypeName | null | undefined;
};
export function isSignedRequiredOnboardingTask({
  maybeDocusignSigned,
  signatureId,
  status,
  type,
}: IsSignedRequiredOnboardingTaskParams) {
  return (
    type === "onboarding_flow" &&
    (maybeDocusignSigned === true || signatureId != null || status === "done")
  );
}
