import { Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTheme } from "shared/hooks/use-theme";
import { RoadmapStage } from "src/constants/client-roadmap";
import {
  isRequiredTaskStatus,
  roadmapStageBody,
  roadmapStageTitle,
  roadmapStageToTaskType,
  isSignedRequiredOnboardingTask,
} from "src/utils/client-roadmap";
import RoadmapCallToAction, {
  RoadmapCallToActionFragment,
} from "./roadmap-call-to-action";
import { FragmentOf, graphql, unmaskFragment } from "shared/gql";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import GuidedFlowStepDoneIcon from "../../icons/custom/done";
import {
  TaskStatus,
  TaskTypeName,
} from "shared/generated/string_unions/graphql";
import { useIsVerySmallScreen } from "src/hooks/use-is-very-small-screen";

export const RoadmapStageCardFragment = graphql(
  /* GraphQL */ `
    fragment RoadmapStageCard on Task {
      type
      status
      maybeDocusignSigned
      signatureId
      ...RoadmapCallToAction
    }
  `,
  [RoadmapCallToActionFragment]
);

const RoadmapStageCard = ({
  isCurrentStage,
  disabled,
  roadmapStage,
  stageNumber,
  numStages,
  taskFrags,
  width,
}: {
  isCurrentStage: boolean;
  disabled: boolean;
  roadmapStage: RoadmapStage;
  numStages: number;
  stageNumber: number;
  taskFrags: FragmentOf<typeof RoadmapStageCardFragment>[];
  width?: number;
}) => {
  const theme = useTheme();
  const isVerySmallScreen = useIsVerySmallScreen();
  const tasks = taskFrags.map((frag) =>
    unmaskFragment(RoadmapStageCardFragment, frag)
  );
  const isClientDoneWithTask = areAllRequiredTasksDoneForStage({
    tasks,
    roadmapStage,
  });
  const hasRequiredTasksForStage =
    tasks.filter((task) => task.type === roadmapStageToTaskType[roadmapStage])
      .length > 0;
  return (
    <Stack
      sx={{
        opacity: disabled ? 0.2 : 1,
        width,
        border: `1px solid ${theme.palette.divider}`,
        background: "#FFF",
        borderRadius: 2,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ px: 3, py: 2.5 }}
      >
        <Box>
          <Typography variant="overline" color="charcoal">{`${
            stageNumber + 1
          } of ${numStages}`}</Typography>
        </Box>
        <Box>
          {isClientDoneWithTask ? (
            <GuidedFlowStepDoneIcon />
          ) : (
            <RadioButtonUncheckedRoundedIcon htmlColor="#E8EAED" />
          )}
        </Box>
      </Stack>
      <Divider sx={{ width: "100%" }} />
      <Box sx={{ p: 3 }}>
        <Box sx={{ my: 1 }}>
          <Typography variant={isVerySmallScreen ? "subtitle2" : "subtitle1"}>
            {roadmapStageTitle(roadmapStage)}
          </Typography>
        </Box>
        <Box sx={{ mb: 3 }}>
          <Typography variant={isVerySmallScreen ? "body2" : "body1"}>
            {roadmapStageBody(roadmapStage)}
          </Typography>
        </Box>
        {isCurrentStage &&
          hasRequiredTasksForStage &&
          !isClientDoneWithTask && (
            <RoadmapCallToAction
              roadmapStage={roadmapStage}
              taskFrags={tasks}
            />
          )}
      </Box>
    </Stack>
  );
};

export default RoadmapStageCard;

type AreAllRequiredTasksDoneForStageParams = {
  tasks: {
    type: TaskTypeName | null | undefined;
    status: TaskStatus | null | undefined;
    maybeDocusignSigned: boolean | null | undefined;
    signatureId: string | number | null | undefined;
  }[];
  roadmapStage: RoadmapStage;
};
function areAllRequiredTasksDoneForStage({
  tasks,
  roadmapStage,
}: AreAllRequiredTasksDoneForStageParams) {
  const taskTypeForRoadmapStage = roadmapStageToTaskType[roadmapStage];
  let hasRequiredTasksForStage = false;
  const hasNoIncompleteRequiredOnboardingTasks =
    tasks.filter((task) => {
      if (
        task.type === taskTypeForRoadmapStage &&
        isRequiredTaskStatus({ status: task.status })
      ) {
        hasRequiredTasksForStage = true;
      }
      return taskTypeForRoadmapStage === "onboarding_flow"
        ? !isSignedRequiredOnboardingTask(task)
        : task.status === "todo" || task.status === "in_progress";
    }).length === 0;
  return hasRequiredTasksForStage && hasNoIncompleteRequiredOnboardingTasks;
}
