import { View } from "react-native";
import { useTheme } from "shared/hooks/use-theme";
import { XSavvyLogo } from "../x-components/x-savvy-logo";

export const LOGO_HEIGHT = 34;

export const XSavvyLogoHeader = () => {
  const theme = useTheme();
  return (
    <View
      style={{
        height: LOGO_HEIGHT,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: LOGO_WIDTH,
        }}
      >
        <XSavvyLogo fill={theme.palette.neutral?.[300]} />
      </View>
    </View>
  );
};

const LOGO_WIDTH = 92;
