import { Typography } from "@mui/material";
import { XTypographyProps } from "./types";
import { useModifiedStyleForWeb } from "shared/theme";

export function XTypography({ children, href, ...props }: XTypographyProps) {
  const style = useModifiedStyleForWeb(props.style);
  return (
    <Typography component="span" {...props} style={style}>
      {children}
    </Typography>
  );
}
