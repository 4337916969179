import { ReactComponentLike } from "prop-types";
import { AuthGuard } from "../components/authentication/auth-guard";

export function withClientGuard<T>(Component: ReactComponentLike) {
  return (props: T) => (
    <AuthGuard forClient>
      <Component {...props} />
    </AuthGuard>
  );
}
