/* eslint-disable no-restricted-imports */
import { LinearProgress, LinearProgressProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dividerColor } from "shared/theme";

//TODO - switch to using styled()
const useStyles = makeStyles(() => ({
  root: ({ fixed }: { value?: number; fixed?: boolean }) => {
    const baseStyles = {
      height: 12,
      border: `1px solid ${dividerColor}`,
      borderRadius: 0,
      background: "#FAFBFB",
    };
    if (fixed) {
      return {
        ...baseStyles,
        position: "fixed",
        zIndex: 10,
        top: 0,
      };
    }
    return baseStyles;
  },
  bar: ({ value }: { value?: number; fixed?: boolean }) => {
    return {
      borderRadius: 0,
      background: `linear-gradient(90deg, #F4EEE8 ${
        100 - (value || 0)
      }%, #175242 100%)`,
    };
  },
}));

type GradientProgressBarProps = LinearProgressProps & {
  fixed?: boolean;
};

const GradientProgressBar = (props: GradientProgressBarProps) => {
  const { value, fixed, ...other } = props;

  const styles = useStyles({ value, fixed });
  const classes = { root: styles.root, bar: styles.bar };

  return (
    <LinearProgress
      classes={classes}
      variant="determinate"
      value={value}
      {...other}
    />
  );
};

export default GradientProgressBar;
