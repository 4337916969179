import { ClientStage } from "shared/generated/graphql";
import { TaskTypeName } from "shared/generated/string_unions/graphql";

export const enum RoadmapStage {
  INTRO_CALL = "INTRO_CALL",
  INTAKE_FLOW = "INTAKE_FLOW",
  ACCOUNT_SETUP = "ACCOUNT_SETUP",
  ONBOARDING_FLOW = "ONBOARDING_FLOW",
  SIT_BACK_AND_WAIT = "SIT_BACK_AND_WAIT",
  COMPLETED = "COMPLETED",
}

export const BASE_CLIENT_ROADMAP_STAGE_ORDER = [
  RoadmapStage.INTRO_CALL,
  RoadmapStage.INTAKE_FLOW,
  RoadmapStage.ACCOUNT_SETUP,
  RoadmapStage.ONBOARDING_FLOW,
  RoadmapStage.SIT_BACK_AND_WAIT,
];

export const CLIENT_STAGE_TO_ROADMAP_STAGE: Record<
  ClientStage | "undefined" | "null",
  RoadmapStage
> = {
  // TODO: Update to final stages and support net-new
  [ClientStage.IntakeFlow]: RoadmapStage.INTAKE_FLOW,
  [ClientStage.PostIntakeWaitingArea]: RoadmapStage.ONBOARDING_FLOW,
  [ClientStage.AccountSetupFlow]: RoadmapStage.ACCOUNT_SETUP,
  [ClientStage.OnboardingFlow]: RoadmapStage.ONBOARDING_FLOW,
  [ClientStage.PostOnboardingWaitingArea]: RoadmapStage.SIT_BACK_AND_WAIT,
  [ClientStage.FullyOnboarded]: RoadmapStage.COMPLETED,
  undefined: RoadmapStage.SIT_BACK_AND_WAIT,
  null: RoadmapStage.SIT_BACK_AND_WAIT,
};

export const ROADMAP_STAGE_TO_TASK_TYPE: Record<
  RoadmapStage,
  TaskTypeName | null
> = {
  [RoadmapStage.INTRO_CALL]: null,
  [RoadmapStage.INTAKE_FLOW]: "initial_intake_form",
  [RoadmapStage.ACCOUNT_SETUP]: "account_setup",
  [RoadmapStage.ONBOARDING_FLOW]: "onboarding_flow",
  [RoadmapStage.SIT_BACK_AND_WAIT]: null,
  [RoadmapStage.COMPLETED]: null,
};
