import { useEffect, useState } from "react";
import { WindowDimensions } from "./types";

export function useFrozenScreenDimensions(): WindowDimensions {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>({
    windowHeight: 0,
    windowWidth: 0,
  });
  useEffect(() => {
    setWindowDimensions({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
    });
  }, []);
  return windowDimensions;
}
